import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/Header"

import AboutHero from "../sections/about/AboutHero"
import AboutStory from "../sections/about/AboutStory"
import AboutMission from "../sections/about/AboutMission"
import AboutVision from "../sections/about/AboutVision"
import AboutValues from "../sections/about/AboutValues"
import AboutTeam from "../sections/about/AboutTeam"
import AboutPartnerBrand from "../sections/about/AboutPartnerBrand"

import HistoryRecognitionCTA from "../components/CallToAction/HistoryRecognitionCTA"
import EmergencyEstimateCTA from "../components/CallToAction/EmergencyEstimateCTA"

const AboutPage = (pageData, pageContext) => {
  const HeroData = pageData.data.contentfulAboutPage.aboutHero
  const StoryData = pageData.data.contentfulAboutPage.aboutStory
  const MissionData = pageData.data.contentfulAboutPage.aboutMission
  const VisionData = pageData.data.contentfulAboutPage.aboutVision
  const CoreValueData = pageData.data.contentfulAboutPage.aboutCoreValues
  const HistoryCtaData = pageData.data.contentfulAboutPage.aboutHistoryCta
  const GlobalRecognitionData =
    pageData.data.contentfulAboutPage.aboutGlobalRecognitionCta
  const EmergencyData = pageData.data.contentfulAboutPage.aboutEmergencyCta
  const EstimateData = pageData.data.contentfulAboutPage.aboutEstimateCta
  const teamSectionTitle = pageData.data.contentfulAboutPage.teamSectionTitle
  const AllPeoples = pageData.data.allContentfulPeople
  const BrandSectionTitle = pageData.data.contentfulAboutPage.brandSectionTitle
  const AllPartners = pageData.data.allContentfulPartner
  const AllBrands = pageData.data.allContentfulBrand

  return (
    <Layout>
      <Seo
        title={pageData.data.contentfulAboutPage.metaTitle}
        description={
          pageData.data.contentfulAboutPage.metaDescription.metaDescription
        }
      />
      <div className="sticky-wrapper sticky">
        <Header />
      </div>
      <AboutHero data={HeroData} />
      <AboutStory data={StoryData} />
      <AboutMission data={MissionData} />
      <AboutVision data={VisionData} />
      <AboutValues data={CoreValueData} />
      <HistoryRecognitionCTA
        leftData={HistoryCtaData}
        rightData={GlobalRecognitionData}
      />
      <AboutTeam title={teamSectionTitle} data={AllPeoples} />
      <AboutPartnerBrand
        title={BrandSectionTitle}
        partners={AllPartners}
        brands={AllBrands}
      />
      <EmergencyEstimateCTA leftData={EmergencyData} rightData={EstimateData} />
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query AboutPageQuery {
    contentfulAboutPage {
      metaTitle
      metaDescription {
        metaDescription
      }
      aboutHero {
        title
        videoUrl
      }
      aboutStory {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        features {
          feature
          amount
        }
      }
      aboutMission {
        title
        image {
          gatsbyImageData(quality: 100)
        }
        secondTitle
        description {
          childMarkdownRemark {
            html
          }
        }
      }
      aboutVision {
        title
        secondTitle
        description {
          childMarkdownRemark {
            html
          }
        }
        image {
          gatsbyImageData(quality: 100)
        }
      }
      aboutCoreValues {
        title
        features {
          description
          value
        }
      }
      aboutHistoryCta {
        title
        image {
          gatsbyImageData(quality: 100)
        }
      }
      aboutGlobalRecognitionCta {
        title
        image {
          gatsbyImageData(quality: 100)
        }
      }
      teamSectionTitle
      aboutEstimateCta {
        title
        linkText
        image {
          gatsbyImageData(quality: 100)
        }
      }
      brandSectionTitle
      aboutEmergencyCta {
        title
        linkText
        image {
          gatsbyImageData(quality: 100)
        }
      }
    }
    allContentfulPeople(sort: { fields: order }) {
      edges {
        node {
          name
          facebook
          linkedIn
          twitter
          description {
            childMarkdownRemark {
              html
            }
          }
          role
          supportedRegion
          image {
            gatsbyImageData(quality: 100)
          }
        }
      }
    }
    allContentfulPartner(sort: { fields: order }) {
      edges {
        node {
          logo {
            gatsbyImageData(quality: 100)
          }
          name
          logoWidth
        }
      }
    }
    allContentfulBrand(sort: { fields: order }) {
      edges {
        node {
          image {
            gatsbyImageData(quality: 100)
          }
          title
          imageWidth
        }
      }
    }
  }
`
