import React from "react"
import styled from "styled-components"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import ArrowIcon from "../Icons/ArrowIcon"
import PrimaryOutlineButton from "../Button/PrimaryOutlineButton"
import BreakpointUp from "../Media/BreakpointUp"

const Wrap = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  ${BreakpointUp.lg`
		flex-direction:row;
	`}
`
const LeftCTA = styled.div`
  position: relative;
  z-index: 1;
  background: #06488e;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;

  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  ${BreakpointUp.xl`
		padding-top:100px;
		padding-bottom:100px;
	`}
  ${BreakpointUp.xxl`
		padding-left: calc(50vw - 675px);
		padding-right: 30px;	
		width: 50vw;
		padding-right: 70px;	
		padding-top:160px;
		padding-bottom:160px;
	`}
`
const RightCTA = styled.div`
  position: relative;
  z-index: 1;

  background: #0059b2;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;

  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  ${BreakpointUp.xl`
		padding-top:100px;
		padding-bottom:100px;
	`}
  ${BreakpointUp.xxl`
		padding-right: calc(50vw - 675px);
		padding-left: 30px;	
		width: 50vw;
		padding-left: 70px;	
		padding-top:160px;
		padding-bottom:160px;
	`}
`

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -15px;
`

const GridCol = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;

  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  & strong {
    display: block;
    position: relative;
    z-index: 2;
    font-family: 'UniNeueBold', sans-serif;
    font-weight: 800;
    color: #fff;
    max-width: 305px;
    width: 100%;
    font-size: 18px;
    line-height: 26px;
    ${BreakpointUp.md`
			font-size: 22px;
			line-height: 32px;
		`}
    ${BreakpointUp.xxl`
			font-size: 24px;
			line-height: 34px;
		`}
  }
`
const GridAuto = styled.div`
  position: relative;
  padding: 0 15px;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
  text-align: right;
  & .btn {
    position: relative;
    z-index: 2;
    width: 48px;
    height: 48px;
    padding: 0;
    ${BreakpointUp.lg`
			width: 68px;
			height: 68px;
		`}
  }
`
const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.15;
  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }
`

class HistoryRecognitionCTA extends React.Component {
  render() {
    const { leftData, rightData } = this.props
    return (
      <Wrap>
        <LeftCTA>
          <Background>
            <GatsbyImage image={getImage(leftData.image)} alt="AES" />
          </Background>
          <Grid>
            <GridCol>
              <strong>{leftData.title}</strong>
            </GridCol>
            <GridAuto>
              <Link to="/timeline">
                <PrimaryOutlineButton icon={<ArrowIcon />} />{" "}
              </Link>
            </GridAuto>
          </Grid>
        </LeftCTA>
        <RightCTA>
          <Background>
            <GatsbyImage image={getImage(rightData.image)} alt="AES" />
          </Background>
          <Grid>
            <GridCol>
              <strong>{rightData.title}</strong>
            </GridCol>
            <GridAuto>
              <Link to="/why-choose-us">
                <PrimaryOutlineButton icon={<ArrowIcon />} />{" "}
              </Link>
            </GridAuto>
          </Grid>
        </RightCTA>
      </Wrap>
    )
  }
}

export default HistoryRecognitionCTA
