import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import VideoBlock from "../../components/VideoBlock"
import MVVideoModal from "../../components/MVVideoModal"
import { BreadCrumb, SectionPageTitle } from "../../components/SectionTags"
import BreakpointUp from "../../components/Media/BreakpointUp"

const Wrap = styled.div`
  background: #ffffff;
  padding: 70px 0 0;
  ${BreakpointUp.sm`
		background: #F5F5F5;
	`}
  @media(min-width: 1025px) {
    padding: 110px 0 0;
  }
`

const VideoWrap = styled.div`
  width: 100%;
  max-width: 1120px;
  margin: auto;
  border-radius: 8px;
  & video {
    width: 100%;
    height: 100%;
  }
  & .mvVideoContainer .video-js {
    border-radius: 8px;
  }
`

class AboutHero extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isVideoModalOpen: false,
      currentVideo: { src: "", type: "" },
    }
    this.handleVideo = this.handleVideo.bind(this)
    this.openVideoModal = this.openVideoModal.bind(this)
    this.closeVideoModal = this.closeVideoModal.bind(this)
  }
  playVideo() {
    this.refs.vidRef.play()
  }
  openVideoModal(url) {
    this.setState({
      currentVideo: {
        type: "video/youtube",
        src: url,
      },
      isVideoModalOpen: true,
    })
  }
  closeVideoModal() {
    this.setState({ isVideoModalOpen: false })
  }
  handleVideo(url) {
    this.openVideoModal(url)
  }

  render() {
    const { data } = this.props
    return (
      <Wrap>
        <MVVideoModal
          isOpen={this.state.isVideoModalOpen}
          autoPlay
          src={this.state.currentVideo.src}
          type={this.state.currentVideo.type}
          controls
          onClose={this.closeVideoModal}
        />
        <div className="container">
          <BreadCrumb>
            <Link to="/"><span>Home</span></Link> About AES
          </BreadCrumb>
          <SectionPageTitle textAlign="center" mb="30px" maxWidth="970px">{data.title}</SectionPageTitle>
          <VideoWrap>
            <VideoBlock
              action={() => this.handleVideo(data.videoUrl)}
              video={data.videoUrl}
            />
          </VideoWrap>
        </div>
      </Wrap>
    )
  }
}

export default AboutHero
