import React from "react"
import styled from "styled-components"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import BreakpointUp from "../../components/Media/BreakpointUp"
import {Container} from "../../components/SectionTags"
import ArrowIcon from "../../components/Icons/ArrowIcon"
import BackArrowIcon from "../../components/Icons/BackArrowIcon"

const Wrap = styled.div`
  position: relative;
  background: ${(props) => props.bg};
  padding: 40px 0;
  ${BreakpointUp.md`
		padding:60px 0;
	`}
  ${BreakpointUp.xl`
		padding:100px 0;
	`}
	${BreakpointUp.xxl`
		padding:120px 0;
	`}
	& h2, & strong {
    margin-bottom: 0;
  }
  & strong {
    display: block;
    font-family: 'UniNeueBold', sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 34px;
  }
`
Wrap.defaultProps = {
  bg: "#fff",
}
const PartnerWrap = styled.div`
  margin:0 0 30px 0;
`
const PartnerItem = styled.div`
  position: relative;
  background: white;
  border: 1px solid #dedede;
  border-radius: 8px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
  height: 242px;
  margin: 30px 15px;
  display:flex;
  align-items:center;
  justify-content:center;
`
const BrandWrap = styled.div`
  margin:0 ;
  position: relative;
`
const BrandItem = styled.div`
  position: relative;
  background: white;
  border: 1px solid #dedede;
  border-radius: 8px;
  box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
  text-align: center;
  height: 160px;
  margin: 30px 15px;
  display:flex;
  align-items:center;
  justify-content:center;
`


const SlickArrow = styled.div`
  text-align: center;
  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    background: #fff;
    border: 1px solid #dedede;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 38px;
    height: 38px;
    ${BreakpointUp.xl`		
			width: 48px;
			height: 48px;
		`}
    ${BreakpointUp.xxl`		
			width: 58px;
			height: 58px;
		`}
		& svg {
      transition: transform ease 0.2s;
    }
  }
`
const RightArrow = styled.div`
  right: -10px;
	& :hover {
    & svg {
      transform: translateX(3px);
    }
  }
`
const LeftArrow = styled.div`
  left: -10px;
	& :hover {
    & svg {
      transform: translateX(-3px);
    }
  }
`


function SampleNextArrow(props) {
  const { className, style } = props
  return <div className={className} style={{ ...style, display: "none" }} />
}

function SamplePrevArrow(props) {
  const { className, style } = props
  return <div className={className} style={{ ...style, display: "none" }} />
}


class AboutPartnerBrand extends React.Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }
  next() {
    this.slider.slickNext()
  }
  previous() {
    this.slider.slickPrev()
  }
  render() {
    const { partners, brands, bg } = this.props
    const brandSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 1,
      autoplaySpeed: 2000,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            centerMode: false,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            centerMode: false,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            centerMode: false,
          },
        },
        {
          breakpoint: 567,
          settings: {
            slidesToShow: 1,
            centerMode: false,
          },
        },
      ],
    }
    const partnerSettings = {
      infinite: false,      
      slidesToShow: 3,
      slidesToScroll: 1,      
      arrows: false,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,            
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,            
          },
        },
      ],
    }
    return (
      <Wrap bg={bg}>
        <Container>
          {/* <h2>{title}</h2> */}
          <h2>Our Partners and Brands We Repair</h2>
          <PartnerWrap>
            <Slider {...partnerSettings}>
              {partners.edges.map((item, i) => {
                return (
                  <div key={i}>
                    <PartnerItem>
                      <GatsbyImage
                        image={getImage(item.node.logo)}
                        alt={item.node.name}
                      />
                    </PartnerItem>
                  </div>
                )
              })}
            </Slider>
          </PartnerWrap>         
        </Container>

        <Container>
          <strong>Brands We Repair</strong>
          <BrandWrap>
            <SlickArrow>
              <LeftArrow onClick={this.previous} className="slick-arrow">
                <BackArrowIcon fill="#FAB702" />
              </LeftArrow>
              <RightArrow onClick={this.next} className="slick-arrow">
                <ArrowIcon fill="#FAB702" />
              </RightArrow>
            </SlickArrow>
            <Slider {...brandSettings} ref={(c) => (this.slider = c)}>
              {brands.edges.map((item, i) => {
                return (
                  <div key={i}>
                    <BrandItem>
                      <GatsbyImage
                        image={getImage(item.node.image)}
                        alt={item.node.title}
                      />
                    </BrandItem>
                  </div>
                )
              })}
            </Slider>
          </BrandWrap>
          <p>All product names, trademarks, brands and logos used on this site are the property of their respective owners. The depiction, description or sale of products featuring these names, trademarks, brands and logos is for identification purposes only and is not intended to indicate any affiliation with or authorization by any rights holder.</p>
        </Container>
      </Wrap>
    )
  }
}
export default AboutPartnerBrand
