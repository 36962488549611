import React from "react"
import styled from "styled-components"
import BreakpointUp from "../../components/Media/BreakpointUp"

const Wrap = styled.div`
  background: #edf8ff;
  padding: 40px 0 20px;
  ${BreakpointUp.md`
		padding:60px 0;
	`}
  ${BreakpointUp.xl`
		padding:100px 0;
	`}
`
const ContentGrid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 30px;
  grid-row-gap: 20px;
  align-items: center;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr);
  }
`
const ShadowTextHeading = styled.div`
  position: relative;
`
const ShadowText = styled.div`
  position: absolute;
  color: #0059b2;
  opacity: 0.06;
  font-family: 'UniNeueBold', sans-serif;
  font-weight: 800;
  top: -15px;
  left: 0;

  left: 0;
  font-size: 36px;
  line-height: 58px;
  ${BreakpointUp.md`
		left: -1vw;
		font-size: 44px;
		line-height: 58px;
	`}
  ${BreakpointUp.lg`
		left: -2vw;
		font-size: 64px;
		line-height: 48px;
		top: -2px;
	`}
	${BreakpointUp.xl`
		top: -10px;
		left: -2vw;
		font-size: 74px;
		line-height: 48px;
	`}
	${BreakpointUp.xxl`
		left: -5vw;
		font-size: 84px;
		line-height: 58px;
	`}
`
const Value = styled.div`
  background: white;
  border-left: 6px solid #fab702;
  padding: 30px 40px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s;
  &:after {
    transition: all 0.3s;
  }
  & strong,
  & p {
    z-index: 1;
    position: relative;
  }
  & strong {
    display: block;
    margin-bottom: 5px;
    font-family: 'UniNeueBold', sans-serif;
    font-weight: 800;
    font-size: 18px;
    line-height: 26px;
    @media (min-width: 768px) {
      font-size: 22px;
      line-height: 28px;
    }
    @media (min-width: 992px) {
      font-size: 24px;
      line-height: 34px;
    }
  }
  & p {
    font-size: 16px;
    line-height: 28px;
    color: #333333;
  }
  &:before,
  &:after {
    background: #fab702;
    content: "";
    position: absolute;
  }
  &:after {
    height: 100%;
    left: 0;
    top: 0;
    width: 0;
  }
  &:hover {
    & strong,
    & p {
      color: #fff;
    }
    &:after {
      width: 100%;
    }
  }

  @media (max-width: 600px) {
    padding: 15px 20px;
    & strong {
      margin-bottom: 6px;
    }
  }
`
class AboutValues extends React.Component {
  render() {
    const { data } = this.props
    return (
      <Wrap>
        <div className="container">
          <ContentGrid>
            <ShadowTextHeading>
              <ShadowText>{data.title}</ShadowText>
              <h2>{data.title}</h2>
            </ShadowTextHeading>
            {data.features.map((item, i) => {
              return (
                <Value key={i}>
                  <strong>{item.value}</strong>
                  <p>{item.description}</p>
                </Value>
              )
            })}
          </ContentGrid>
        </div>
      </Wrap>
    )
  }
}

export default AboutValues
