import React from "react"
import styled from "styled-components"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { MarkdownContent, Container, SectionTitle } from "../../components/SectionTags"
import BreakpointUp from "../../components/Media/BreakpointUp"
// import Slider from "react-slick"
// import ItemHover from "../../components/ItemHover"
// import { dropdown } from "../../components/Animation"
// import Social from "../../components/Social"

const Wrap = styled.div`
  background: #f5f5f5;
  padding: 40px 0 0;
  ${BreakpointUp.md`
		padding:60px 0 20px;		
	`}
  ${BreakpointUp.xl`
		padding:100px 0 60px;		
	`}
	${BreakpointUp.xxl`
		padding:120px 0 80px;		
	`}
`
const TeamMember = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding-bottom: 10px;
  margin-bottom: 40px;
  ${BreakpointUp.xxl`
		padding-bottom: 20px;
		margin-bottom: 50px;	
	`}
`
const ItemTeamMember = styled.div`
  display: flex;
  flex-wrap:wrap;
  margin:0 -15px;
  align-items: flex-start;
  ${BreakpointUp.xxl`
    margin:0 -30px;
	`}
`
const Photo = styled.div`
  position: relative;
  width: 100%;
  padding:0 15px;
  margin-bottom:30px;
  border-radius: 8px;
  ${BreakpointUp.sm`
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  `}
  ${BreakpointUp.lg`
    flex: 0 0 25%;
    max-width: 25%;
  `}
  ${BreakpointUp.xxl`
    padding:0 30px;
	`}
  .gatsby-image-wrapper{
    border-radius: 8px;
    ${BreakpointUp.sm`
      width: 100%;
      height: 100%;
    `}
  } 
`
const TeamDesc = styled.div`
  position: relative;
  width: 100%;
  padding:0 15px;
  ${BreakpointUp.sm`
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  `}
  ${BreakpointUp.xxl`
    padding:0 30px;
	`}
  & h3{
    margin-bottom:0;
  }
  & .role{
    display: block;
    font-family: 'UniNeueBold', sans-serif;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    @media (min-width: 768px) {
      font-size: 20px;
      line-height: 24px;
    }
    @media (min-width: 1600px) {
      font-size: 22px;
      line-height: 28px;
    }

  }
  & .region{
    color: #666;
    font-size: 14px;
    line-height: 24px;
    display: block;
    margin-bottom: 15px;
  }

`
// const TeamSocial = styled.div`
//   position: relative;
//   width: 100%;
//   display: flex;
//   align-items: center;
//   padding:0 15px;
//   margin-bottom:30px;
//   ${BreakpointUp.sm`
//     order: 1;
//     flex: 0 0 auto;
//     width: auto;
//     max-width: 100%;
//   `}
//   ${BreakpointUp.xxl`
//     padding:0 30px;
// 	`}
//   .social-list{
//     margin-bottom:0;
//     ${BreakpointUp.sm`
//       flex-direction:column;
//     `}
//     .social-icon{
//       + .social-icon{
//         ${BreakpointUp.sm`
//           margin-top:10px;
//         `}
//       }
//     }
//   }
// `

// const TeamSlider = styled.div`
//   padding-left: calc((100% - 1350px) / 2);
// `
// const Card = styled.div`
//   position: relative;
//   padding: 15px;
//   padding-bottom: 40px;
//   text-align: center;
//   .gatsby-image-wrapper {
//     margin: auto;
//     width: calc(100% - 20px);
//     height: 285px;
//     border-top-left-radius: 8px;
//     border-top-right-radius: 8px;
//   }
//   &:hover {
//     cursor: pointer;
//   }
//   @media (max-width: 600px) {
//     .gatsby-image-wrapper {
//       height: 275px;
//       width: calc(100% - 5px);
//     }
//   }
// `
// const CardText = styled.div`
//   width: calc(100% + 10px);
//   margin-left: -5px;
//   box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
//   padding: 20px 30px;
//   border-radius: 8px;
//   text-align: left;
//   & strong {
//     display: block;
//     font-family: 'UniNeueBold', sans-serif;
//     font-weight: 800;
//     color: #000;
//     font-size: 20px;
//     line-height: 30px;
//     @media (min-width: 768px) {
//       font-size: 22px;
//       line-height: 32px;
//     }
//     @media (min-width: 992px) {
//       font-size: 24px;
//       line-height: 34px;
//     }
//   }
//   & p {
//     margin-bottom: 0;
//   }
// `
// const CardDetail = styled.div`
//   position: absolute;
//   width: calc(100% - 20px);
//   height: calc(100% - 55px);
//   top: 0;
//   left: -5px;
//   background: #fab702;
//   margin: 15px;
//   padding: 45px 20px;
//   text-align: left;
//   border-radius: 8px;
//   box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
//   display: ${(props) => props.display};
//   animation: ${dropdown} ease 0.5s;
//   & strong {
//     display: block;
//     font-family: 'UniNeueBold', sans-serif;
//     font-weight: 800;
//     color: #fff;
//     margin-bottom: -5px;
//     font-size: 20px;
//     line-height: 30px;
//     @media (min-width: 768px) {
//       font-size: 22px;
//       line-height: 32px;
//     }
//     @media (min-width: 992px) {
//       font-size: 24px;
//       line-height: 34px;
//     }
//   }
//   & label {
//     margin-top: 0px;
//     color: #fff;
//     font-size: 14px;
//     line-height: 24px;
//   }
//   & p {
//     font-size: 16px;
//     line-height: 28px;
//     color: #fff;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     display: -webkit-box;
//     -webkit-line-clamp: 5;
//     -webkit-box-orient: vertical;
//   }
//   & .social-list{
//     .social-icon{
//       > a{
//         border-color:#fff;
//         & svg{
//           fill:#fff;
//         }
//         &:hover, &:focus{
//           border-color:#000;
//           > svg{
//             fill:#000;
//           }
//         }
//       }
//     }
//   }
// `

class AboutTeam extends React.Component {
  render() {
    const { title, data } = this.props
    // const settings = {
    //   dots: false,
    //   swipeToSlide: true,
    //   infinite: true,
    //   speed: 500,
    //   slidesToShow: 5,
    //   slidesToScroll: 1,
    //   autoplay: true,
    //   autoplaySpeed: 2000,
    //   arrows: false,
    //   responsive: [
    //     {
    //       breakpoint: 1200,
    //       settings: {
    //         slidesToShow: 4
    //       },
    //     },
    //     {
    //       breakpoint: 992,
    //       settings: {
    //         slidesToShow: 3
    //       },
    //     },
    //     {
    //       breakpoint: 768,
    //       settings: {
    //         slidesToShow: 2
    //       },
    //     },
    //     {
    //       breakpoint: 567,
    //       settings: {
    //         slidesToShow: 1
    //       },
    //     },
    //   ],
    // }
    return (
      <Wrap>
        <Container>
          <SectionTitle>{title}</SectionTitle>
          {data.edges.map((people, i) => {
            return (
              <TeamMember>
                <ItemTeamMember>
                  <Photo>
                    <GatsbyImage image={getImage(people.node.image)} alt="AES" />               
                  </Photo>
                  {/* <TeamSocial>
                    <Social />
                  </TeamSocial> */}
                  <TeamDesc>
                    <h3>{people.node.name}</h3>
                    <span className="role">({people.node.role})</span>
                    <span className="region">Supported Region: {people.node.supportedRegion}</span>
                    <MarkdownContent
                      dangerouslySetInnerHTML={{
                        __html:
                          people.node.description.childMarkdownRemark.html,
                      }}
                    />
                  </TeamDesc>
                </ItemTeamMember>
              </TeamMember>
            )
          })}
        </Container>
        {/* <Section pt="0" pb="0">
          <TeamSlider>
            <Container maxWidth="100%">
              <Slider {...settings}>
                {data.edges.map((people, i) => {
                  return (
                    <ItemHover>
                      {(hoverItem) => (
                        <Card>
                          <GatsbyImage
                            image={getImage(people.node.image)}
                            alt="AES"
                          />
                          <CardText>
                            <strong>{people.node.name}</strong>
                            <p>{people.node.role}</p>
                          </CardText>
                          <CardDetail display={hoverItem ? "block" : "none"}>
                            <strong>{people.node.name}</strong>
                            <label>{people.node.role}</label>
                            <MarkdownContent
                              dangerouslySetInnerHTML={{
                                __html:
                                  people.node.description.childMarkdownRemark
                                    .html,
                              }}
                            />
                            <Social />
                          </CardDetail>
                        </Card>
                      )}
                    </ItemHover>
                  )
                })}
              </Slider>
            </Container>
          </TeamSlider>
        </Section> */}
      </Wrap>
    )
  }
}

export default AboutTeam
