import React from "react"
import styled from "styled-components"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

import Mask from "../../components/Mask"
import BreakpointUp from "../../components/Media/BreakpointUp"

const Wrap = styled.div`
  padding-left: calc(50vw - 675px);
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  ${BreakpointUp.md`
		padding-bottom:60px;
	`}
  ${BreakpointUp.xl`
		padding-bottom:100px;
	`}
	@media(max-width: 1440px) {
    padding-left: 2.5%;
  }
  @media (max-width: 767px) {
    display: block;
    padding-left: 0px;
    position: relative;
  }
`
const ImageWrap = styled.div`
  position: relative;
  width: 50%;
  max-width: 675px;
  border-radius: 8px;
  h2 {
    position: absolute;
    line-height: 2;
    width: 260px;
    text-align: center;
    background: #fab702;
    color: #ffffff;
    top: 0;
    left: calc(50% - 130px);
    z-index: 1;
  }
  .gatsby-image-wrapper {
    height: 495px;
    border-radius: 8px;
  }
  @media (max-width: 1024px) {
    h2 {
      line-height: 2 !important;
    }
    .gatsby-image-wrapper {
      height: 269px;
    }
  }
  @media (max-width: 767px) {
    max-width: none;
    width: 95%;
    margin: auto;
    .gatsby-image-wrapper {
      height: 335px;
    }
  }
`
const RightWrap = styled.div`
  padding-left: calc(10vw + 50px);
  margin-left: -10vw;
  width: 70vw;
  background: #edf8ff;
  @media (max-width: 1440px) {
    padding-right: 5%;
  }
  @media (max-width: 767px) {
    width: 100%;
    margin-left: 0;
    padding-left: 2.5%;
    padding-right: 2.5%;
    & p {
      margin-bottom: 20px !important;
    }
  }
`
const TextWrap = styled.div`
  position: relative;
  margin: 60px 0;
  width: 100%;
  max-width: 620px;
  & p {
    margin-bottom: 34px;
  }
  @media (max-width: 767px) {
    margin: -150px 0 0;
    padding: 165px 0 30px;
  }
`
const Title = styled.strong`
  font-family: 'UniNeueBold', sans-serif;
  font-weight: 800;
  margin: 0 0 15px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  color: #0059b2;
  opacity: 0.1;
  display: none;
  
  ${BreakpointUp.xl`
    display: block;
    right: -12vw;
    font-size: 40px;
    line-height: 54px;
  `}
  ${BreakpointUp.xxl`
    font-size: 44px;
    line-height: 58px;
    right: -15vw;
  `}
 
`
class AboutMission extends React.Component {
  render() {
    const { data } = this.props
    return (
      <Wrap>
        <ImageWrap>
          <Mask />
          <h2>{data.title}</h2>
          <GatsbyImage image={getImage(data.image)} alt="AES" />
        </ImageWrap>
        <RightWrap>
          <TextWrap>
            <div
              dangerouslySetInnerHTML={{
                __html: data.description.childMarkdownRemark.html,
              }}
            />
            <Title>{data.secondTitle} </Title>
          </TextWrap>
        </RightWrap>
      </Wrap>
    )
  }
}

export default AboutMission
