import React from "react"
import styled from "styled-components"
import BreakpointUp from "../../components/Media/BreakpointUp"
import { Container } from "../../components/SectionTags"

const Wrap = styled.div`
  margin-top: -300px;
  padding: 340px 0 70px;
  background: white;

  @media (max-width: 1024px) {
    margin-top: -280px;
    padding: 310px 0 60px;
  }
  @media (max-width: 600px) {
    margin-top: -300px;
    padding-bottom: 30px;
  }
`

const Title = styled.strong`
  font-family: 'UniNeueBold', sans-serif;
  font-weight: 800;
  margin: 0 0 15px;
  position: absolute;
  transform: rotate(-90deg);
  top: 180px;
  color: #0059b2;
  opacity: 0.1;
  margin-bottom: 0;
  display: none;
  
  ${BreakpointUp.xl`
    left: -115px;
    display: block;
    font-size: 44px;
    line-height: 58px;
  `}
  @media (min-width: 1280px) {
    left: -150px;
  }
  ${BreakpointUp.xxl`
    font-size: 50px;
    line-height: 66px;
  `}
`
const TextWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const Description = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.xl`
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	`}
`
const Features = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

  ${BreakpointUp.xl`
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	`}
`
const Feature = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  border-bottom: 1px solid #dedede;
  padding: 10px;
  ${BreakpointUp.xxl`		
		padding:20px;
	`}
  & strong {
    color: #fab702;
    font-family: 'UniNeueBold', sans-serif;
    font-weight: 800;
    font-size: 24px;
    line-height: 34px;
    ${BreakpointUp.md`
			font-size: 28px;
			line-height: 38px;
		`}
    ${BreakpointUp.lg`		
			font-size: 34px;
			line-height: 48px;
		`}
		${BreakpointUp.xxl`		
			font-size: 44px;
			line-height: 58px;
		`}
		+ span {
      margin-left: 15px;
      ${BreakpointUp.sm`				
				margin-left:30px;
			`}
    }
  }
  & span {
    font-weight: 600;
    ${BreakpointUp.xxl`		
			font-size: 18px;
			line-height:20px;
		`}
  }
  &:first-child {
    border-top: 1px solid #dedede;
  }
`

class AboutStory extends React.Component {
  render() {
    const { data } = this.props
    return (
      <Wrap>
        <Container maxWidth="1120px">
          <Title>{data.title}</Title>
          <TextWrap>
            <Description>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.description.childMarkdownRemark.html,
                }}
              />
            </Description>
            <Features>
              {data.features.map((item, i) => {
                return (
                  <Feature key={i}>
                    <strong>{item.amount}</strong>
                    <span>{item.feature}</span>
                  </Feature>
                )
              })}
            </Features>
          </TextWrap>
        </Container>
      </Wrap>
    )
  }
}

export default AboutStory
